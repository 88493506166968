<template>
    <div class="Area">
        <main-header menuActive="statistics" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>재택치료 사유별 통계</h2>
                </div>
                <div class="row searchBtn">
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">기간</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_start"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_end"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchListFunc">조회</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-profile">
                            <tbody>
                            <tr>
                                <th class="field text-center font-weight-bold">일자</th>
                                <th class="field text-center font-weight-bold">만12세이하소아</th>
                                <th class="field text-center font-weight-bold">소아의보호자</th>
                                <th class="field text-center font-weight-bold">기저질환보유자</th>
                                <th class="field text-center font-weight-bold">성인1인가구</th>
                                <th class="field text-center font-weight-bold">무증상/경증</th>
                                <th class="field text-center font-weight-bold">그외</th>
                                <th class="field text-center font-weight-bold">합계</th>
                            </tr>
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td class="text-center">{{ data.fregDate }}</td>
                                <td class="text-center text-link" @click="searchDetailFunc('만12세이하소아', data.fregDate, 'G1401')">{{ data.safecare1 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('소아의보호자', data.fregDate, 'G1402')">{{ data.safecare2 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('기저질환보유자', data.fregDate, 'G1403')">{{ data.safecare3 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('성인1인가구', data.fregDate, 'G1404')">{{ data.safecare4 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('무증상/경증', data.fregDate, 'G1406')">{{ data.safecare5 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('그외', data.fregDate, 'G1405')">{{ data.safecare6 | comma }} 명</td>
                                <td class="text-center text-link">{{ data.safecare7 | comma }} 명</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th class="field text-center font-weight-bold">총계</th>
                                <td class="text-center">{{ sum_G1401 | comma }} 명</td>
                                <td class="text-center">{{ sum_G1402 | comma }} 명</td>
                                <td class="text-center">{{ sum_G1403 | comma }} 명</td>
                                <td class="text-center">{{ sum_G1404 | comma }} 명</td>
                                <td class="text-center">{{ sum_G1406 | comma }} 명</td>
                                <td class="text-center">{{ sum_G1405 | comma }} 명</td>
                                <td class="text-center">{{ sum_G1410 | comma }} 명</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'Area',
    data() {
        return {
            ko : ko,
            sch_date_start : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
            sch_date_end : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)).format('YYYY-MM-DD'),
            dataList : [],

            sum_G1401 : 0,
            sum_G1402 : 0,
            sum_G1403 : 0,
            sum_G1404 : 0,
            sum_G1405 : 0,
            sum_G1406 : 0,
            sum_G1410 : 0
        }
    },
    components: { mainHeader, mainFooter, datepicker },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
    },
    // $el 생성후
    mounted() {
        if (this.$route.params.sch_date_start){
            this.sch_date_start = this.$route.params.sch_date_start;
            this.sch_date_end = this.$route.params.sch_date_end;

            this.searchListFunc()
        }else{
            this.searchListFunc()
        }
    },
    methods: {
        // 리스트 검색
        searchListFunc(){
            let params = `{"fromRegDt": "${this.dateToString(this.sch_date_start)}", "toRegDt": "${this.dateToString(this.sch_date_end)}"}`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/safecare?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'statisticsSafecare');
            formData.append('sch_date_start', this.$moment(this.sch_date_start).format('YYYY-MM-DD'));
            formData.append('sch_date_end', this.$moment(this.sch_date_end).format('YYYY-MM-DD'));
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'searchListFuncResult');
            */
        },
        searchListFuncResult(response) {
            let result = response;
            this.dataList = result.list;

            let sum_G1401 = 0
            let sum_G1402 = 0
            let sum_G1403 = 0
            let sum_G1404 = 0
            let sum_G1405 = 0
            let sum_G1406 = 0
            let sum_G1410 = 0

            result.list.forEach(function (item) {
                sum_G1401 = parseInt(item.safecare1) +  sum_G1401;
                sum_G1402 = parseInt(item.safecare2) +  sum_G1402;
                sum_G1403 = parseInt(item.safecare3) +  sum_G1403;
                sum_G1404 = parseInt(item.safecare4) +  sum_G1404;
                sum_G1406 = parseInt(item.safecare5) +  sum_G1406;
                sum_G1405 = parseInt(item.safecare6) +  sum_G1405;
            });

            this.sum_G1401 = sum_G1401;
            this.sum_G1402 = sum_G1402;
            this.sum_G1403 = sum_G1403;
            this.sum_G1404 = sum_G1404;
            this.sum_G1406 = sum_G1406;
            this.sum_G1405 = sum_G1405;
            this.sum_G1410 = sum_G1401 + sum_G1402 + sum_G1403 + sum_G1404 + sum_G1406 + sum_G1405;
        },
        excelDownFunc(){
            console.log("엑셀다운로드");
            /*
            let url=this.rootUrl+'/_excel/excel_statistics_safecare.php?1=1';
            if(this.sch_date_start && this.sch_date_end){
                url += '&sch_date_start='+this.$moment(this.sch_date_start).format('YYYY-MM-DD');
                url += '&sch_date_end='+this.$moment(this.sch_date_end).format('YYYY-MM-DD');
            }
            this.excelExport(url);
            */

            let params = `{
                "fromRegDt": "${this.dateToString(this.sch_date_start)}", 
                "toRegDt": "${this.dateToString(this.sch_date_end)}"
            }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/safecare?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            let excelData = [];

            // 엑셀 헤더 추가
            excelData.push(['일자', '만12세이하소아', '소아의보호자', '기저질환보유자',	'성인1인가구', '무증상/경증', '그외', '합계']);

            let totalSafecare1 = 0; // 총계 초기화
            let totalSafecare2 = 0; // 총계 초기화
            let totalSafecare3 = 0; // 총계 초기화
            let totalSafecare4 = 0; // 총계 초기화
            let totalSafecare5 = 0; // 총계 초기화
            let totalSafecare6 = 0; // 총계 초기화
            let totalSafecare7 = 0; // 총계 초기화

            // 엑셀 데이터 추가
            dataList.forEach((data, index) => {

                let sum1 = data.safecare1;
                totalSafecare1 += sum1; // 총계 누적
                
                let sum2 = data.safecare2;
                totalSafecare2 += sum2; // 총계 누적
                
                let sum3 = data.safecare3;
                totalSafecare3 += sum3; // 총계 누적
                
                let sum4 = data.safecare4;
                totalSafecare4 += sum4; // 총계 누적
                
                let sum5 = data.safecare5;
                totalSafecare5 += sum5; // 총계 누적
                
                let sum6 = data.safecare6;
                totalSafecare6 += sum6; // 총계 누적

                let sum7 = data.safecare7;
                totalSafecare7 += sum7; // 총계 누적
                
                excelData.push([
                    data.fregDate, 
                    data.safecare1, 
                    data.safecare2, 
                    data.safecare3, 
                    data.safecare4, 
                    data.safecare5, 
                    data.safecare6, 
                    data.safecare7 
                ]);
            });

            // 총계 행 추가
            excelData.push(['총계', totalSafecare1, totalSafecare2, totalSafecare3, 
            totalSafecare4, totalSafecare5, totalSafecare6, totalSafecare7]); // 총계 행 추가

            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정통계_재택치료사유별.xlsx');
        },
        // 상세페이지
        searchDetailFunc(title, date, idx){
            let data={};
            data.sch_date_start = this.sch_date_start;
            data.sch_date_end = this.sch_date_end;
            data.title = title;
            data.date = date;
            data.idx = idx;
            data.sch_type = 'safecare';

            this.$router.push({ name: 'AdminStatisticsDetail', params: {data} })
        },
        dateToString(dateString) {
            // const dateString = "Fri Jan 01 2021 09:00:00 GMT+0900 (한국 표준시)";
            const date = new Date(dateString); // Date 객체 생성
            const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`; // 년, 월, 일을 추출하여 원하는 형식으로 조합

            // 월과 일이 한 자리 수일 경우 앞에 0을 붙이는 함수
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }

            return formattedDate;
        },
    }
}
</script>

<style scoped>
</style>
